import React from "react";
import { graphql, HeadProps, PageProps } from "gatsby";
// MUI
import Typography from "@mui/joy/Typography";
// Components
import Layout from "components/Layout";
import Page, { PageTitle } from "components/Page";
import HeadTags from "components/Head";

const OUTPUTS = [
  ["Hark!", "Looks like the page be lost to the seas..."],
  ["Oh no!", "It looks like that page isn't ready yet..."],
  ["Bah!", "Looks like Mic forgot to add more content as per usual..."],
  [
    "Blast!",
    "Another worker probably got their fingers stuck in the printing press again...",
  ],
];

type DataProps = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
};

const NotFoundPage = (props: PageProps<DataProps>) => {
  const [title, message] = React.useMemo(() => {
    const index = Math.floor(Math.random() * Math.floor(OUTPUTS.length));
    return OUTPUTS[index];
  }, []);
  return (
    <Layout title={"404"} slug={"404"}>
      <Page id="404">
        <PageTitle>{title}</PageTitle>
        <Typography component="h3" level="h3">
          {message}
        </Typography>
      </Page>
    </Layout>
  );
};

export const Head = (props: HeadProps<DataProps>) => (
  <HeadTags
    {...props}
    pageContext={{
      title: "404",
      slug: "404",
      type: "website",
    }}
  />
);

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default NotFoundPage;
